
export default {
  name: 'Post',
  props: {
    unlazyBlockImage: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      required: true,
    },
    highlights: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    time: null,
  }),
  computed: {
    categories() {
      const filtered = this.post.categories.nodes.filter(
        (category) =>
          // Rimuovo le categorie che non hanno pagine dedicate e nella configurazione sono segnalate in navigation/exclude
          !this.$configuration.navigation.exclude.includes(
            this.$getCategoryRoute(category.slug, 'wpSlug').key
          )
      )
      return filtered
    },
  },
  mounted() {
    this.time = this.$readingTime(this.post.content)
  },
}
